@charset "utf-8";
@import "../lib/reset.min.css";
@import "../_icons.scss";
@import "../_variable.sass";
@import "../_mixin.sass";

.is-pc {
  display: none; }

.m-title {
  text-align: center;
  margin-bottom: 32px;

  .titleJa {
    @include setJa;
    color: $black;
    font-size: 16px;
    margin-bottom: 12px; }

  .titleEn {
    @include setEn;
    color: $blue;
    font-size: 10px; } }

.m-txt {
  @include setFont(12px, 1.75);
  color: $black;
  letter-spacing: 0.05em; }

.m-brand {
  li {
    display: inline-block;
    opacity: 0.4;

    &+li {
      margin-left: 16px; }

    img {
      height: 15px; } } }

.m-tag {
  margin-top: -8px;
  display: flex;
  flex-wrap: wrap;

  li {
    margin-top: 8px;

    &+li {
      margin-left: 8px; }

    a {
      color: $blue;
      font-size: 10px;
      font-feature-settings: 'palt';
      text-decoration: none;
      letter-spacing: 0.05em;
      min-height: 20px;
      padding: 6px 8px;
      border: 1px solid $blue;
      border-radius: 20px;
      display: inline-block;
      box-sizing: border-box;

      &:hover {
        color: $white;
        background: $blue; } } } }

.m-select {
  margin-bottom: 44px;

  li {
    position: relative;

    &+li {
      margin-top: 8px; }

    select {
      width: 100%;
      height: 40px;
      background: $gray-e;
      border: 0;
      padding: 0 16px;
      appearance: none;
      border-radius: 0; }

    &:after {
      content: '';
      width: 8px;
      height: 6px;
      background: url(../../images/common/icn_arrow.svg) no-repeat 50% 50%;
      background-size: 8px 6px;
      position: absolute;
      top: 16px;
      right: 16px;
      pointer-events: none; } } }

.m-btn {
  a {
    @include setEn;
    color: $blue;
    font-size: 14px;
    text-align: center;
    text-decoration: none;
    margin: 0 auto;
    border: 1px solid $blue;
    display: block; } }

.m-link {
  a {
    color: $blue;
    font-size: 10px;
    font-feature-settings: 'palt';
    text-decoration: none;
    letter-spacing: 0.05em;
    min-height: 20px;
    padding: 6px 8px;
    border: 1px solid $blue;
    border-radius: 20px;
    display: inline-block;
    box-sizing: border-box;

    &:hover {
      color: $white;
      background: $blue; } } }

.m-itemList {
  &>li {
    margin-bottom: 36px;

    &>a {
      text-decoration: none;
      width: 100%;
      display: block;
      position: relative;

      .thumb {
        @include setImg(100%);
        height: calc((100vw - 48px) * 0.674);
        background: $gray-e;
        margin-bottom: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        overflow: hidden;

        img {
          width: auto;
          height: auto;
          max-width: 100%;
          max-height: 220px; } }

      .brand {
        margin-bottom: 20px; }

      .title {
        @include setJa;
        color: $black;
        @include setFont(16px, 1.5, 20px); }

      .txt {
        @include setFont(12px, 1.75, 22px); }

      .date {
        @include setEn;
        color: $blue;
        font-size: 12px;
        margin-bottom: 16px;
        display: block; }

      .brand,
      .date {
        &:last-child {
          margin-bottom: 0; } } }

    .tag {
      &+.brand {
        margin-top: 24px; } } } }

.m-gallery {
  width: 100%;
  position: relative;
  overflow: hidden;

  .imgs {
    width: 1000%;
    background: $gray-e;
    margin-bottom: 24px;
    display: flex;

    li {
      width: calc(100vw - 48px);
      text-align: center;

      img {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 240px;
        position: relative;
        top: 50%;
        transform: translateY(-50%); } } }

  .captions {
    margin-bottom: 20px;

    li {
      @include setFont(12px, 1.5);
      color: $gray-a;
      display: none;

      &.is-active {
        display: block; } } }

  .prev,
  .next {
    width: 32px;
    height: 32px;
    position: absolute;
    top: calc(50% - 56px);
    z-index: 1;

    &.is-disable {
      background-color: $gray-a;
      pointer-events: none; } }

  .prev {
    background: $blue url(../../images/common/icn_arrow_w.svg) no-repeat 50% 50%;
    background-size: 6px 8px;
    left: 0;
    transform: scale(-1, 1); }

  .next {
    background: $blue url(../../images/common/icn_arrow_w.svg) no-repeat 50% 50%;
    background-size: 6px 8px;
    right: 0; }

  .page {
    color: $gray-a;
    font-size: 12px;
    text-align: right; } }

.m-tab {
  margin-bottom: 40px;
  display: flex;
  flex-wrap: wrap;
  margin-left: 1px;

  li {
    width: 33.3%;
    text-align: center;
    position: relative;
    margin-left: -1px;

    &:after {
      content: '';
      width: 1px;
      height: 20px;
      background: $gray-c;
      position: absolute;
      top: 13px;
      right: 0; }

    &.is-active,
    &:nth-child(3n) {
      &:after {
        display: none; } }

    &.is-active {
      pointer-events: none;

      a {
        color: $white;
        background: $blue;

        img {
          filter: brightness(0) invert(100%);
          opacity: 1; } } }

    a {
      @include setEn;
      color: $gray-a;
      font-size: 11px;
      text-decoration: none;
      line-height: 40px;
      width: 100%;
      height: 40px;
      display: block;
      box-sizing: border-box;
      padding: 0 16px;

      img {
        max-width: 100%;
        height: 17px;
        opacity: 0.5; }

      &:hover {
        opacity: 0.5; } } } }

.m-pager {
  margin-top: 48px;
  display: flex;

  .btn {
    &.prev {
      margin-right: 28px; }

    a {
      font-size: 12px;
      line-height: 32px;
      width: calc(50vw - 42px);
      height: 32px;
      border-radius: 16px;
      display: block; }

    &.is-disable {
      a {
        color: $gray-c;
        border-color: $gray-c;
        pointer-events: none; } } }

  .num,
  .trim {
    display: none; } }

.m-other {
  margin: 48px 0 48px;
  border-bottom: 1px solid $gray-c;
  padding-bottom: 48px;

  .btn {
    a {
      line-height: 40px;
      width: 200px;
      height: 40px;
      border-radius: 20px; } } }

.m-empty {
  padding: 24px 0;

  .txt {
    @include setJa;
    @include setFont(14px, 1.5);
    text-align: center; } }

.m-vision {
  padding: 120px 0 180px;
  position: relative;
  overflow: hidden;

  .titleEn {
    @include setEn;
    color: $blue;
    font-size: 10px;
    margin-bottom: 24px; }

  .titleJa {
    @include setImg(100%);
    margin-bottom: 28px;
    position: relative;
    left: -2px; }

  .copy {
    @include setImg(100%);
    margin-bottom: 28px;
    position: relative;
    left: -4px; }

  .img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;

    .i {
      width: 48px;
      height: 48px;
      position: absolute;
      overflow: hidden;

      &:before {
        content: '';
        width: 48*2px;
        height: 48*2px;
        position: absolute;
        top: 0;
        left: 0; }

      &:nth-child(odd) {
        &:before {
          animation: bgAnim1 10s linear infinite; } }

      &:nth-child(even) {
        &:before {
          animation: bgAnim2 10s linear infinite; } }

      &:nth-child(1) {
        top: 48*0px;
        right: 48*3px;

        &:before {
          background: url(../../images/index/bg_vision_g4.png) repeat 0 0;
          background-size: 48px 48px; } }

      &:nth-child(2) {
        top: 48*0px;
        right: 48*0px;

        &:before {
          background: url(../../images/index/bg_vision_a4.png) repeat 0 0;
          background-size: 48px 48px; } }

      &:nth-child(3) {
        top: 48*1px;
        right: 48*4px;

        &:before {
          background: url(../../images/index/bg_vision_e1.png) repeat 0 0;
          background-size: 48px 48px; } }

      &:nth-child(4) {
        top: 48*1px;
        right: 48*2px;

        &:before {
          background: url(../../images/index/bg_vision_d3.png) repeat 0 0;
          background-size: 17px 17px;
          animation: bgAnimD3 10s linear infinite; } }

      &:nth-child(5) {
        top: 48*1px;
        right: 48*1px;

        &:before {
          background: url(../../images/index/bg_vision_e4.png) repeat 0 0;
          background-size: 48px 48px; } }


      &:nth-child(5) {
        top: 48*1px;
        right: 48*1px;

        &:before {
          background: url(../../images/index/bg_vision_e4.png) repeat 0 0;
          background-size: 48px 48px; } }

      &:nth-child(6) {
        top: 48*2px;
        right: 48*1px;

        &:before {
          background: url(../../images/index/bg_vision_b3.png) repeat 0 0;
          background-size: 48px 48px; } }

      &:nth-child(7) {
        top: 48*2px;
        right: 48*0px;

        &:before {
          background: url(../../images/index/bg_vision_d1.png) repeat 0 0;
          background-size: 65px 65px;
          width: 65*2px;
          height: 65*2px;
          animation: bgAnimD1 10s linear infinite; } }

      &:nth-child(8) {
        bottom: 48*2px;
        right: 48*5px;

        &:before {
          background: url(../../images/index/bg_vision_a3.png) repeat 0 0;
          background-size: 48px 48px; } }

      &:nth-child(9) {
        bottom: 48*2px;
        right: 48*3px;

        &:before {
          background: url(../../images/index/bg_vision_c4.png) repeat 0 0;
          background-size: 48px 48px; } }

      &:nth-child(10) {
        bottom: 48*2px;
        right: 48*1px;

        &:before {
          background: url(../../images/index/bg_vision_f2.png) repeat 0 0;
          background-size: 48px 48px; } }

      &:nth-child(11) {
        bottom: 48*1px;
        right: 48*3px;

        &:before {
          background: url(../../images/index/bg_vision_d2.png) repeat 0 0;
          background-size: 34px 34px;
          animation: bgAnimD2 10s linear infinite; } }

      &:nth-child(12) {
        bottom: 48*1px;
        right: 48*2px;

        &:before {
          background: url(../../images/index/bg_vision_e3.png) repeat 0 0;
          background-size: 48px 48px; } }

      &:nth-child(13) {
        bottom: 48*1px;
        right: 48*0px;

        &:before {
          background: url(../../images/index/bg_vision_b2.png) repeat 0 0;
          background-size: 48px 48px; } }

      &:nth-child(14) {
        bottom: 48*0px;
        right: 48*6px;

        &:before {
          background: url(../../images/index/bg_vision_c2.png) repeat 0 0;
          background-size: 48px 48px; } }

      &:nth-child(15) {
        bottom: 48*0px;
        right: 48*4px;

        &:before {
          background: url(../../images/index/bg_vision_b1.png) repeat 0 0;
          background-size: 48px 48px; } } } } }

@keyframes bgAnim1 {
  0% {
    transform: translate3d(-48px,-48px,0); }
  100% {
    transform: translate3d(0,0,0); } }

@keyframes bgAnim2 {
  0% {
    transform: translate3d(0,0,0); }
  100% {
    transform: translate3d(-48px,-48px,0); } }

@keyframes bgAnimD1 {
  0% {
    transform: translate3d(-65px,-65px,0); }
  100% {
    transform: translate3d(0,0,0); } }

@keyframes bgAnimD2 {
  0% {
    transform: translate3d(0,0,0); }
  100% {
    transform: translate3d(-34px,-34px,0); } }

@keyframes bgAnimD3 {
  0% {
    transform: translate3d(0,0,0); }
  100% {
    transform: translate3d(-42.5px,-42.5px,0); } }

@keyframes bgAnimD4 {
  0% {
    transform: translate3d(0,0,0); }
  100% {
    transform: translate3d(-46.5px,-46.5px,0); } }

