@charset "utf-8";
@import "../lib/reset.min.css";
@import "../_icons.scss";
@import "../_variable.sass";
@import "../_mixin.sass";

body.career {
  .l-visual {
    margin-bottom: 48px;
    position: relative;

    .img {
      @include setImg(100%);
      overflow: hidden;

      img {
        width: 175%;
        position: relative;
        left: -37.5%; } }

    .txts {
      width: calc(100% - 24px);
      background: $white;
      box-sizing: border-box;
      margin: -98px 0 0 24px;
      padding: 24px 0 0 24px;
      position: relative;
      z-index: 1;

      .titleEn {
        @include setEn;
        color: $blue;
        font-size: 10px;
        margin-bottom: 20px; }

      .titleJa {
        @include setJa;
        @include setFont(18px, 1.5, 24px);
        color: $black; }

      .subtitle {
        @include setJa;
        @include setFont(14px, 1.5, 24px); } } }

  .l-article {
    margin-bottom: 40px;
    border-bottom: 1px solid $gray-c;
    padding-bottom: 40px;

    .l-sub {
      margin-bottom: 24px;

      .img {
        @include setImg(100%); }

      .btn {
        display: none; } }

    .l-main {
      .date {
        @include setEn;
        color: $blue;
        font-size: 10px;
        margin-bottom: 24px;
        display: block; }

      .title {
        @include setJa;
        @include setFont(18px, 1.5, 40px); }

      .subtitle {
        @include setJa;
        @include setFont(14px, 1.5, 28px, 40px); }

      .txt {
        @include setFont(12px, 2, 28px);
        letter-spacing: 0.05em;

        &+.btn {
          margin-top: 40px; } }

      .btn {
        a {
          line-height: 40px;
          width: 200px;
          height: 40px;
          border-radius: 20px; } } } }

  .l-other {
    margin: 0;
    border: 0;
    padding: 0; } }
