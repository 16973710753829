@charset "utf-8";
@import "../lib/reset.min.css";
@import "../_icons.scss";
@import "../_variable.sass";
@import "../_mixin.sass";

body.contact {
  .contact_form {
    overflow: hidden;
    height: auto;
    margin-top: 32px;
    padding: 12px 24px;
    background: $gray-e; }

  .form_message {
    @include setJa;
    font-size: 14px;
    line-height: 1.5;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
    color: $black; }

  .txt {
    font-size: 12px;
    line-height: 1.5;
    color: $gray-a; }

  .contact_form table {
    margin-top: 0;
    margin-left: auto;
    margin-right: auto;
    text-align: left; }

  tr {
    margin-bottom: 10px;
    display: block; }

  th {
    vertical-align: middle;
    .item {
      font-size: 12px;
      font-weight: 600;
      color: $blue; }
    .required {
      color: $blue;
      font-size: 10px;
      font-feature-settings: 'palt';
      text-decoration: none;
      letter-spacing: .05em;
      border: 1px solid $blue;
      padding: 3px 6px;
      border-radius: 20px;
      box-sizing: border-box;
      display: inline-block;
      margin-left: 8px; } }

  td {
    vertical-align: middle;
    padding-top: 12px;
    padding-bottom: 12px;
    display: block; }

  select.category {
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    border-radius: 0;
    background: $white;
    width: 100%;
    height: 40px;
    border: none;
    padding: 0 12px;
    box-sizing: border-box;
    font-size: 12px;

    &[disabled="disabled"] {
      background: transparent;

      &+.wpcf7c-conf-hidden+.arrow {
        display: none; } } }

  label[for="category"] {
    br {
      display: none; } }

  .arrow {
    position: absolute;
    top: 3px;
    right: 12px;
    font-size: 10px;
    color: $blue;
    pointer-events: none; }

  input {
    &.textbox {
      width: 100%;
      height: 40px;
      padding: 0 12px;
      margin: 0;
      border: none;
      box-sizing: border-box;
      font-size: 12px; }
    &.file {
      display: none; } }

  .uploadButton {
    font-size: 10px;
    position: relative;
    overflow: hidden;
    border-radius: 26px;
    border: 1px solid $blue;
    color: $blue;
    text-align: center;
    line-height: 40px;
    width: 110px;
    height: 40px;
    cursor: pointer;
    margin-top: 5px;
    margin-bottom: 5px;
    &:hover {
      background: $blue;
      color: $white; }
    input[type=file] {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      cursor: pointer;
      opacity: 0; } }

  .uploadValue {
    display: none;
    width: 360px;
    background: none;
    margin: 16px 20px;
    padding: 3px;
    border: none;
    font-feature-settings: 'palt'; }

  textarea {
    font-size: 12px;
    clear: both;
    width: 100%;
    height: 120px;
    padding: 12px;
    border: none;
    box-sizing: border-box; }

  .wpcf7-form {
    margin-bottom: 75px; }

  .wpcf7c-conf {
    border: 0;
    background: none; }

  .uploadButton input.wpcf7c-conf {
    background: none;
    display: none; }

  .accept {}

  .accepttxt {
    font-size: 12px;
    margin-left: 6px; }

  span.wpcf7-list-item {
    margin: 0; }

  .submit-button {
    border-style: none;
    margin-top: 16px; }

  .confirm {
    text-align: center;
    input {
      font-size: 12px;
      width: 200px;
      height: 40px;
      border-style: none;
      line-height: 40px;
      border-radius: 20px;
      border: 1px solid $blue;
      background: transparent;
      color: $blue;
      cursor: pointer; } }

  .back {
    text-align: center;
    margin-bottom: 16px;
    input {
      font-size: 12px;
      width: 200px;
      height: 40px;
      border-style: none;
      line-height: 40px;
      border-radius: 20px;
      border: 1px solid $blue;
      background: transparent;
      color: $blue;
      cursor: pointer; } }

  .submit {
    text-align: center;
    margin-bottom: 24px;
    input {
      font-size: 12px;
      width: 200px;
      height: 40px;
      border-style: none;
      line-height: 40px;
      border-radius: 20px;
      border: 1px solid $blue;
      background: $blue;
      color: $white;
      cursor: pointer; } }

  .submit-button input:disabled {
    display: block;
    border-radius: 32px;
    margin: 0 auto !important;
    font-feature-settings: 'palt';
    letter-spacing: .05em;
    background: none;
    color: $blue;
    text-align: center;
    text-decoration: none;
    border: 1px solid $blue;
    display: block;
    box-sizing: border-box; }

  .submit-button input:disabled {
    border: 1px solid $gray-c;
    color: $gray-c;
    pointer-events: none; }

  span.wpcf7-not-valid-tip {
    margin-top: 5px; }

  div {
    &.wpcf7-validation-errors, &.wpcf7-acceptance-missing {
      text-align: center;
      border: none; } }

  .wpcf7-validation-errors {
    display: none !important; }

  label[for="accept"] {
    width: 90%;
    margin: 2em auto;
    text-align: left; }


  div.wpcf7 .ajax-loader {
    display: none; } }

body.thanks {
  .form_message {
    @include setJa;
    font-size: 14px;
    line-height: 1.5;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    color: $black;
    margin-bottom: 32px; }

  .btn {
    a {
      font-size: 12px; } } }
