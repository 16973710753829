@charset "utf-8";
@import "../lib/reset.min.css";
@import "../_icons.scss";
@import "../_variable.sass";
@import "../_mixin.sass";

body.privacy,
body.terms {
  .l-privacy,
  .l-terms {
    .title {
      @include setJa;
      @include setFont(14px, 1.5, 18px, 36px); }

    .txt {
      @include setMargin(12px, 1.75, 28px); } } }
