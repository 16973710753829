@charset "utf-8";
@import "../lib/reset.min.css";
@import "../_icons.scss";
@import "../_variable.sass";
@import "../_mixin.sass";

body.works,
body.news {
  .l-visual {
    margin-bottom: 40px;

    .img {
      @include setImg(100%);
      margin-bottom: 40px; }

    .txts {
      width: 100%;
      box-sizing: border-box;
      background: url(../../images/common/bg_title_1c_sp.png) no-repeat 100% 0;
      background-size: 144px 144px;

      &.bijutsuTecho {
        background: url(../../images/common/bg_title_bijutsu_techo_1c_sp.png) no-repeat 100% 0;
        background-size: 144px 144px; }

      &.businessSolution {
        background: url(../../images/common/bg_title_business_solution_1c_sp.png) no-repeat 100% 0;
        background-size: 144px 144px; }

      &.designCenter {
        background: url(../../images/common/bg_title_design_center_1c_sp.png) no-repeat 100% 0;
        background-size: 144px 144px; }

      &.winart {
        background: url(../../images/common/bg_title_winart_1c_sp.png) no-repeat 100% 0;
        background-size: 144px 144px; }

      &.bijutsuKentei {
        background: url(../../images/common/bg_title_bijutsu_kentei_1c_sp.png) no-repeat 100% 0;
        background-size: 144px 144px; }

      .info {
        .date {
          @include setEn;
          color: $blue;
          font-size: 10px;
          margin-bottom: 20px;
          display: block; }

        .brand {
          margin-bottom: 20px; } }

      .title {
        @include setJa;
        @include setFont(18px, 1.5, 20px, 24px);
        color: $black;
        text-align: justify;
        word-break: break-all;

        br {
          display: none; } }

      .txt {
        @include setFont(12px, 1.75);
        letter-spacing: 0.05em;
        text-align: justify;
        word-break: break-all; } } }

  .l-article {
    .l-main,
    .l-sub {
      border-top: 1px solid $gray-c; }

    .l-main {
      &>*:first-child {
        margin-top: 40px !important; }

      h1 {
        @include setJa;
        @include setFont(18px, 1.5, 24px, 40px); }

      h2 {
        @include setJa;
        @include setFont(14px, 1.5, 24px, 24px); }

      img {
        max-width: 100%;
        height: auto; }

      .txt {
        @include setFont(12px, 1.75, 24px, 24px);
        letter-spacing: 0.05em;
        text-align: justify;
        word-break: break-all;

        a {
          color: $blue;
          text-decoration: none;

          &:before {
            content: '';
            background: url(../../images/common/icn_link.svg) no-repeat 0 0;
            background-size: 12px 12px;
            width: 12px;
            height: 12px;
            display: inline-block;
            position: relative;
            top: 2px;
            margin-right: 4px; } } }

      &>.img {
        @include setImg(100%);
        margin: 40px 0; }

      &>.caption {
        @include setFont(11px, 1.5, 40px, -20px);
        color: $gray-a; } }

    .l-sub {
      margin-top: 40px;
      padding-top: 40px;

      .btn {
        text-align: center;
        margin-bottom: 32px;

        a {
          line-height: 40px;
          width: 200px;
          height: 40px;
          border-radius: 20px; } }

      .titleJa {
        @include setJa;
        @include setFont(14px, 1.5, 20px, 28px);
        color: $black;

        &:first-child {
          margin-top: 0; } }

      .titleEn {
        @include setEn;
        color: $blue;
        font-size: 14px;
        margin-bottom: 20px; }

      .credit {
        @include clearfix;
        margin-bottom: -18px;

        dt {
          @include setEn;
          @include setFont(12px, 1.5, 18px);
          color: $gray-a;
          width: 96px;
          float: left;
          clear: both; }

        dd {
          @include setFont(12px, 1.5, 18px);
          color: $gray-a;
          float: left; } }

      .list {
        @include clearfix;

        dt {
          @include setFont(12px, 1.5, 12px);
          color: $blue; }

        dd {
          @include setFont(12px, 1.5, 18px);
          color: $gray-a; } }

      .link {
        &+.link {
          margin-top: 8px; } } } } }
