@charset "utf-8";
@import "../lib/reset.min.css";
@import "../_icons.scss";
@import "../_variable.sass";
@import "../_mixin.sass";

body.company {
  .l-company {
    margin-bottom: 32px;

    .items {
      border-bottom: 1px solid $gray-c;

      .wrap {
        .item {
          display: flex;
          border-top: 1px solid $gray-c;
          padding: 16px 0;

          .label {
            color: $blue;
            font-size: 12px;
            letter-spacing: 0.05em;
            width: 92px; }

          .txt {
            width: calc(100% - 92px);
            @include setFont(12px, 1.75);
            letter-spacing: 0.05em; } } } } }

  .l-map {
    margin-bottom: 40px;

    iframe {
      width: 100%;
      height: 240px; } }

  .l-history {
    .items {
      .item {
        margin-bottom: 1px;
        display: flex;

        .year {
          width: 100px;
          background: $blue;
          text-align: center;

          .inner {
            width: 100%;
            padding: 24px 0;
            position: relative;
            top: 50%;
            transform: translateY(-50%);

            .en {
              @include setEn;
              color: $white;
              font-size: 28px;
              letter-spacing: 0.05em;
              margin-bottom: 10px; }

            .ja {
              color: $white;
              font-size: 10px;
              font-weight: bold;
              letter-spacing: 0.05em; } } }

        .body {
          width: calc(100% - 100px);

          .inner {
            width: 100%;
            padding: 24px 0 24px 20px;
            box-sizing: border-box;
            position: relative;
            top: 50%;
            transform: translateY(-50%);

            .img {
              height: 60px;
              margin-bottom: 16px;

              img {
                height: 100%; } }

            .txts {
              .title {
                @include setFont(12px, 1.5);
                color: $black;
                letter-spacing: 0.05em;
                font-weight: bold; }

              .txt {
                @include setFont(10px, 1.75, 0, 16px);
                color: $black;
                letter-spacing: 0.05em; } } } } } } } }


