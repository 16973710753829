@charset "utf-8";
@import "../lib/reset.min.css";
@import "../_icons.scss";
@import "../_variable.sass";
@import "../_mixin.sass";


body.index {
  .l-visual {
    margin-bottom: 32px;
    position: relative;

    .list {
      li {
        display: none;

        &.is-active {
          display: block; }

        a {
          display: block;
          position: relative;
          text-decoration: none;

          .img {
            @include setImg(100%);
            box-sizing: border-box;
            text-align: center;
            &.booksize {
              img {
                width: 60%; } } }

          .txts {
            width: calc(100% - 24px);
            background: $white;
            box-sizing: border-box;
            margin-top: -24px;
            padding: 24px 24px 0 0;
            position: relative;
            z-index: 1;

            .info {
              .date {
                @include setEn;
                color: $blue;
                font-size: 10px;
                margin-bottom: 20px;
                display: block; } }


            .title {
              @include setJa;
              @include setFont(18px, 1.5, 20px, 20px);
              color: $black; } } } } }

    .prev,
    .next {
      width: 32px;
      height: 32px;
      position: absolute;
      top: calc(28vw - 16px);
      z-index: 1;
      cursor: pointer; }

    .prev {
      background: $blue url(../../images/common/icn_arrow_w.svg) no-repeat 50% 50%;
      background-size: 6px 8px;
      left: 0;
      transform: scale(-1, 1); }

    .next {
      background: $blue url(../../images/common/icn_arrow_w.svg) no-repeat 50% 50%;
      background-size: 6px 8px;
      right: 0; }

    .transition {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      display: none;
      flex-wrap: wrap;
      overflow: hidden;

      .i {
        background: $blue;
        width: calc((100vw - 48px) / 5);
        height: calc((100vw - 48px) / 5);
        opacity: 0; } } }

  .l-information {
    table {
      width: 100%;
      margin: 0 auto;
      margin-bottom: 38px;
      display: block;
      line-height: 1;
      th {
        display: table-cell;
        vertical-align: text-top;
        font-size: 10px;
        height: 18px;
        color: $blue;
        font-family: 'PT Serif',sans-serif;
        font-weight: 400;
        font-style: normal;
        font-feature-settings: 'palt';
        letter-spacing: .05em;
        -webkit-font-smoothing: antialiased;
        margin-right: 20px;
        position: relative; }

      td {
        width: calc(100% - 20px);
        display: inline-block;
        margin-left: 20px;
        vertical-align: text-top;
        line-height: 1.4;

        a {
          width: 100%;
          color: $black;
          font-size: 10px;
          display: inline-block;
          text-decoration: none;
          letter-spacing: .05em;
          &:hover {
            color: $blue; } } } } }

  .l-vision {
    margin-bottom: 56px; }

  .l-brands {
    margin-bottom: 48px;

    .item,
    .businessSolution {
      margin-top: 12px;
      padding: 40px 24px 32px;
      position: relative;
      display: block;

      .logo {
        text-align: center;
        margin-bottom: 32px;

        img {
          height: 36px; } }

      .txt {
        @include setMargin(12px, 1.75, 24px); }

      .btn {
        a {
          line-height: 40px;
          width: 192px;
          height: 40px;
          border-radius: 20px; } }

      &.bijutsuTecho {
        background: url(../../images/common/bg_brand_bijutsu_techo.png) repeat 0 0;
        background-size: 192px 96px; }

      &.businessSolution {
        background: url(../../images/common/bg_brand_business_solution.png) repeat 0 0;
        background-size: 192px 96px; }

      &.designCenter {
        background: url(../../images/common/bg_brand_design_center.png) repeat 0 0;
        background-size: 192px 96px; }

      &.winart {
        background: url(../../images/common/bg_brand_winart.png) repeat 0 0;
        background-size: 192px 96px; }

      &.bijutsuKentei {
        background: url(../../images/common/bg_brand_bijutsu_kentei.png) repeat 0 0;
        background-size: 192px 96px; } }

    .businessSolution {} }

  .l-news {
    .btn {
      a {
        line-height: 40px;
        width: 200px;
        height: 40px;
        border-radius: 20px; } } } }
