@charset "utf-8";
@import "../lib/reset.min.css";
@import "../_icons.scss";
@import "../_variable.sass";
@import "../_mixin.sass";

body {
  color: $black;
  font-size: 14px;
  font-family: Helvetica, Roboto, 'Droid Sans', 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', sans-serif;
  -webkit-text-size-adjust: none;
  padding: 76px 24px 24px;
  box-sizing: border-box;

  // &:before,
  // &:after
  //   content: ''
  //   width: 100%
  //   height: 56px
  //   background: $white
  //   z-index: 100
  //   position: fixed
  //   left: 0

  // &:before
  //   top: 0

  // &:after
 }  //   bottom: 0

li {
  list-style: none; }

.l-header {
  width: 100%;
  height: 76px;
  background: $white;
  margin-bottom: 56px;
  padding-left: 24px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  box-sizing: border-box;

  .logo {
    @include setImg(150px);
    padding-top: 24px; }

  .btn {
    height: 76px;
    width: 32px;
    position: absolute;
    top: 0;
    right: 24px;

    &:before {
      top: 38-4px; }

    &:after {
      top: 38+4px; }

    &:before,
    &:after {
      content: '';
      background: $blue;
      width: 32px;
      height: 3px;
      position: absolute;
      right: 0;
      transition: top 250ms $easeOutCubic; } }

  .inner {
    width: calc(100% - 48px);
    height: calc(100% - 76px);
    background: $blue;
    padding: 20px 24px 0;
    position: fixed;
    top: 76px;
    left: 24px;
    z-index: 100;
    box-sizing: border-box;
    opacity: 0;
    display: none;

    &:after {
      content: '';
      width: 100%;
      height: 24px;
      background: $white;
      position: absolute;
      bottom: 0;
      left: 0; }

    .util {
      position: absolute;
      left: 24px;
      bottom: 48px;
      display: flex;
      justify-content: flex-end;

      .search {
        margin-right: 24px;

        .text {
          color: $white;
          font-size: 12px;
          height: 32px;
          width: calc(100vw - 216px);
          background: $blue;
          border: 0;
          border-bottom: 1px solid rgba(255,255,255,0.3);
          border-radius: 0;

          &:placeholder-shown,
          &::-webkit-input-placeholder {
            @include setEn;
            color: rgba(255,255,255,0.5);
            font-size: 10px; } }

        .submit {
          width: 32px;
          height: 32px;
          background: url(../../images/common/icn_search_w.svg) no-repeat 50% calc(50% + 1px);
          background-size: 16px 16px;
          border: 0;
          cursor: pointer;

          &:hover {
            opacity: 0.5; } } }

      .lang {
        display: flex;

        .ja,
        .en {
          @include setEn;
          color: $blue;
          font-size: 12px;
          text-align: center;
          line-height: 32px;
          background: $white;
          width: 32px;
          height: 32px;
          cursor: pointer;

          &.is-enable {
            color: $white;
            background: rgba(255,255,255,0.1); } } } }

    .nav {
      display: flex;
      flex-wrap: wrap;
      border-bottom: 1px solid rgba(255,2555,255,0.5);

      li {
        width: 100%;

        a {
          @include setEn;
          color: $white;
          font-size: 16px;
          text-align: center;
          text-decoration: none;
          line-height: 64px;
          width: 100%;
          height: 64px;
          display: block; } } }
    .sub-nav {
      display: flex;
      flex-wrap: wrap;
      li {
        width: 50%;

        a {
          @include setEn;
          color: $white;
          font-size: 16px;
          text-align: center;
          text-decoration: none;
          line-height: 64px;
          width: 100%;
          height: 64px;
          display: block; } } } }


  &.is-enable {
    .btn {
      &:before,
      &:after {
        top: 38px;
        transition: top 250ms $easeOutCubic; } } } }

.l-contact {
  background: $blue;
  margin-top: 56px;
  padding: 108px 24px 32px;
  position: relative;

  &:before {
    content: '';
    background: url(../../images/common/icn_contact.svg) no-repeat 0 0;
    background-size: 40px 32px;
    width: 40px;
    height: 32px;
    position: absolute;
    top: 36px;
    left: calc(50% - 20px); }


  .txt {
    @include setFont(12px, 1.75, 32px);
    color: $white;
    letter-spacing: 0.05em;

    br {
      display: none; } }

  .btn {
    text-align: center;

    a {
      @include setEn;
      color: $white;
      font-size: 14px;
      text-align: center;
      text-decoration: none;
      line-height: 40px;
      width: 200px;
      height: 40px;
      border: 1px solid $white;
      border-radius: 20px;
      display: inline-block; } } }

.l-title {
  background: $blue url(../../images/common/bg_title_sp.png) repeat-y calc(100% + 24px) 0;
  background-size: 144px 144px;
  width: 100%;
  height: 144px;
  margin-bottom: 40px;
  padding: 52px 28px 0 28px;
  box-sizing: border-box;

  &.bijutsuTecho {
    background: $blue url(../../images/common/bg_title_bijutsu_techo_sp.png) repeat-y calc(100% + 24px) 0;
    background-size: 144px 144px; }

  &.businessSolution {
    background: $blue url(../../images/common/bg_title_business_solution_sp.png) repeat-y calc(100% + 24px) 0;
    background-size: 144px 144px; }

  &.designCenter {
    background: $blue url(../../images/common/bg_title_design_center_sp.png) repeat-y calc(100% + 24px) 0;
    background-size: 144px 144px; }

  &.winart {
    background: $blue url(../../images/common/bg_title_winart_sp.png) repeat-y calc(100% + 24px) 0;
    background-size: 144px 144px; }

  &.bijutsuKentei {
    background: $blue url(../../images/common/bg_title_bijutsu_kentei_sp.png) repeat-y calc(100% + 24px) 0;
    background-size: 144px 144px; }

  .title {
    @include setJa;
    color: $white;
    font-size: 20px;
    text-shadow: $blue 1px 1px 0, $blue -1px -1px 0, $blue -1px 1px 0, $blue 1px -1px 0, $blue 0px 1px 0, $blue  0-1px 0, $blue -1px 0 0, $blue 1px 0 0;
    margin-bottom: 12px;

    img {
      height: 24px;
      margin-top: -4px; }

    .search {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: inline-block; } }

  .topicpath {
    display: flex;
    transform: scale(0.8);
    transform-origin: 0 0;

    li {
      @include setEn;
      color: $white;
      font-size: 10px;

      &+li {
        margin-left: 8px;

        &:before {
          content: '>';
          display: inline-block;
          margin-right: 8px; } }

      a {
        color: $white;
        text-decoration: none; } } } }

.l-footer {
  margin-top: 48px;

  .info {
    .logo {
      @include setImg(240px);
      margin-bottom: 24px;
      opacity: 0.3; }

    .nav {
      margin-bottom: 24px;
      display: flex;

      li {
        line-height: 0.75;

        &+li {
          margin-left: 10px;
          border-left: 1px solid $gray-c;
          padding-left: 10px; }

        a {
          @include setEn;
          color: $gray-a;
          font-size: 10px;
          text-decoration: none;
          position: relative;
          top: -2px; } } }

    .copyright {
      @include setEn;
      width: 200%;
      color: $gray-a;
      font-size: 14px;
      transform: scale(0.5);
      transform-origin: 0 0; } }

  .link {
    margin-bottom: 40px;

    .brand,
    .social {
      .title {
        @include setEn;
        color: $gray-a;
        font-size: 10px;
        margin-bottom: 24px; } }

    .brand {
      margin-bottom: 48px;

      .list {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: -28px;

        li {
          margin: 0 24px 24px 0;

          &:nth-child(3) {
            margin-right: 0; }

          a {
            opacity: 0.4;

            img {
              height: 18px;

              @media (max-width: 320px) {
                height: 14px; } } } } } }

    .social {
      .list {
        display: flex;

        li {
          img {
            width: 32px; }

          &+li {
            margin-left: 24px; }

          &.twitter {
            margin-right: -4px;
            position: relative;
            top: 1px;

            img {
              width: 37px; } } } } } } }


