@charset "utf-8";
@import "../lib/reset.min.css";
@import "../_icons.scss";
@import "../_variable.sass";
@import "../_mixin.sass";

body.business {
  .l-vision {
    margin-bottom: 44px;

    .titleJa {
      @include setImg(268px); }

    .brand {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 36 - 28px;

      li {
        margin: 0 24px 20px 0;
        opacity: 0.4;

        &:nth-child(3) {
          margin-right: 0; }

        img {
          height: 18px;

          @media (max-width: 320px) {
            height: 14px; } } } }

    .copy {
      @include setImg(310px);
      max-width: 100%; } }

  .l-brand {
    .item {
      &+.item {
        margin-top: 40px; }

      .img {
        @include setImg(100%);
        margin-bottom: 32px; }

      .txts {
        text-align: center;
        box-sizing: border-box;

        .logo {
          margin-bottom: 32px;

          img {
            height: 36px; } }

        .copy {
          @include setJa;
          @include setFont(16px, 1.5, 32px);
          color: $black; }

        .txt {
          @include setMargin(12px, 1.75, 32px);
          text-align: left; }

        .btn {
          width: 140px;
          margin: 0 auto;

          a {
            line-height: 32px;
            width: 140px;
            height: 32px;
            border-radius: 16px; } } } } }

  .l-about {
    margin-bottom: 48px;

    .img {
      width: calc(100% - 24px);
      height: 282px;
      position: relative;
      left: 24px;
      z-index: -1;

      &.bijutsutecho {
        background: url(../../images/business/img_about_bijutsutecho.jpg) no-repeat 0 0;
        background-size: cover; }

      &.businesssolution {
        background: url(../../images/business/img_about_businesssolution.jpg) no-repeat 0 0;
        background-size: cover; }

      &.designcenter {
        background: url(../../images/business/img_about_designcenter.jpg) no-repeat 0 0;
        background-size: cover; }

      &.winart {
        background: url(../../images/business/img_about_winart.jpg) no-repeat 0 0;
        background-size: cover; }

      &.bijutsukentei {
        background: url(../../images/business/img_about_bijutsukentei.jpg) no-repeat 0 0;
        background-size: cover; } }


    .txts {
      width: calc(100% - 24px);
      background: $white;
      margin-top: -60px;
      padding: 24px 24px 0 0;
      box-sizing: border-box;
      a {
        color: $blue;
        text-decoration: none;

        &:before {
          content: '';
          background: url(../../images/common/icn_link.svg) no-repeat 0 0;
          width: 16px;
          height: 16px;
          display: inline-block;
          position: relative;
          top: 2px;
          margin-right: 6px; }
        &:hover {
          opacity: 0.5; } }

      .titleEn {
        @include setEn;
        color: $blue;
        font-size: 10px;
        margin-bottom: 22px; }

      .titleJa {
        @include setJa;
        @include setFont(18px, 1.5, 24px); } } }

  .l-business {
    border-bottom: 1px solid $gray-c;
    padding-bottom: 44px;

    .item {
      &+.item {
        margin-top: 36px; }

      .img {
        text-align: center;
        height: 240px;
        background: $gray-e;
        box-sizing: border-box;
        margin-bottom: 24px;
        padding: 0 24px;

        img {
          width: auto;
          height: auto;
          max-width: 100%;
          max-height: 192px;
          display: inline-block;
          position: relative;
          top: 50%;
          transform: translateY(-50%); } }

      .txts {
        .title {
          @include setJa;
          @include setFont(16px, 1.5, 22px); } }

      .link {
        margin-top: 24px; } } }

  .l-other {
    margin-bottom: 0;
    border-bottom: 0;
    padding-bottom: 0; } }


