@charset "utf-8";

@mixin mediaquery($device) {
  @if $device == pc {
    @media only screen and (min-width: $breakpoint+1) {
      @content; } }
  @else if $device == sp {
    @media only screen and (max-width: $breakpoint) {
      @content; } } }

@mixin clearfix {
  zoom: 1;

  &:after {
    clear: both;
    display: block;
    content: ""; } }

@mixin setFont($font-size: $font-size, $line-height: $line-height, $margin-bottom: 0, $margin-top: 0) {
  font-size: $font-size;
  line-height: $line-height;
  margin-bottom: floor($margin-bottom - ($font-size * $line-height - $font-size) / 2);
  margin-top: floor($margin-top - ($font-size * $line-height - $font-size) / 2); }

@mixin setMargin($font-size: $font-size, $line-height: $line-height, $margin-bottom: 0, $margin-top: 0) {
  margin-bottom: floor($margin-bottom - ($font-size * $line-height - $font-size) / 2);
  margin-top: floor($margin-top - ($font-size * $line-height - $font-size) / 2); }

@mixin setImg($width: auto, $height: auto) {
  width: $width;
  height: $height;

  img {
    width: 100%; } }

@mixin setJa {
  font-family: a-otf-midashi-go-mb31-pr6n, sans-serif;
  font-weight: 600;
  font-style: normal;
  font-feature-settings: 'palt';
  letter-spacing: 0.05em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

@mixin setEn {
  font-family: 'PT Serif', sans-serif;
  font-weight: $fw-regular;
  font-style: normal;
  font-feature-settings: 'palt';
  letter-spacing: 0.05em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }
