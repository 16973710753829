@charset "utf-8";
@import "../lib/reset.min.css";
@import "../_icons.scss";
@import "../_variable.sass";
@import "../_mixin.sass";

body.books {
  .l-visual {
    margin-bottom: 72px;
    position: relative;

    &:after {
      content: '';
      width: calc(100% - 28px);
      height: 100%;
      background: url(../../images/common/bg_brand_bijutsu.png) repeat 0 0;
      background-size: 192px 96px;
      position: absolute;
      top: 28px;
      left: 28px;
      z-index: -1; }

    &.bijutsuTecho {
      &:after {
        background: url(../../images/common/bg_brand_bijutsu_techo.png) repeat 0 0;
        background-size: 192px 96px; } }

    &.businessSolution {
      &:after {
        background: url(../../images/common/bg_brand_business_solution.png) repeat 0 0;
        background-size: 192px 96px; } }

    &.designCenter {
      &:after {
        background: url(../../images/common/bg_brand_design_center.png) repeat 0 0;
        background-size: 192px 96px; } }

    &.winart {
      &:after {
        background: url(../../images/common/bg_brand_winart.png) repeat 0 0;
        background-size: 192px 96px; } }

    &.bijutsuKentei {
      &:after {
        background: url(../../images/common/bg_brand_bijutsu_kentei.png) repeat 0 0;
        background-size: 192px 96px; } }

    .img {
      @include setImg(calc(100% - 24px));
      margin-bottom: 32px; }

    .txts {
      padding: 0 28px 0 56px;

      .date {
        @include setEn;
        color: $blue;
        font-size: 10px;
        margin-bottom: 20px;
        display: block; }

      .title {
        @include setJa;
        @include setFont(18px, 1.5, 24px);
        color: $black;
        text-align: justify;
        word-break: break-all;

        br {
          display: none; } }

      .tag {
        margin-bottom: 32px; }

      .subtitle {
        @include setJa;
        @include setFont(14px, 1.5, 24px);
        color: $black; } } }

  .l-article {
    .l-main,
    .l-sub {
      border-top: 1px solid $gray-c; }

    .l-main {

      img {
        max-width: 100%;
        height: auto; }

      &>*:first-child {
        margin-top: 40px !important; }

      h1 {
        @include setJa;
        @include setFont(18px, 1.5, 24px, 40px); }

      h2 {
        @include setJa;
        @include setFont(14px, 1.5, 24px, 24px); }


      .title {
        @include setFont(12px, 1.5, 16px, 32px);
        color: $blue;
        font-weight: bold;
        text-align: justify;
        word-break: break-all; }

      .subtitle {
        @include setFont(12px, 1.5, 12px, 12px);
        color: $black;
        font-weight: bold; }

      .txt {
        @include setFont(12px, 2, 12px, 12px);
        text-align: justify;
        word-break: break-all;

        a {
          color: $blue;
          text-decoration: none;

          &:before {
            content: '';
            background: url(../../images/common/icn_link.svg) no-repeat 0 0;
            background-size: 12px 12px;
            width: 12px;
            height: 12px;
            display: inline-block;
            position: relative;
            top: 2px;
            margin-right: 4px; } } }

      .link {
        margin: 12px 0 20px; } }

    .l-sub {
      margin-top: 40px;
      padding-top: 40px;

      .btn {
        a {
          font-family: "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, sans-serif;
          font-size: 12px;
          font-weight: bold;
          line-height: 40px;
          width: 200px;
          height: 40px;
          border-radius: 20px;

          span {
            &:before {
              content: '';
              background: url(../../images/publication/icn_amazon.png) no-repeat 0 0;
              background-size: 18px 16px;
              width: 18px;
              height: 16px;
              display: inline-block;
              margin-right: 12px;
              position: relative;
              top: 4px; } } }

        &.buy {
          margin-bottom: 8px; }

        &.repeat {
          margin-bottom: 36px; } }

      .title {
        @include setFont(12px, 1.5, 16px, 32px);
        color: $blue;
        font-weight: bold;
        text-align: justify;
        word-break: break-all; }

      .txt {
        @include setFont(12px, 1.75);
        color: $gray-a;
        text-align: justify;
        word-break: break-all; }

      .info {
        .list {
          @include clearfix;
          margin-bottom: -16px;

          dt {
            @include setFont(12px, 1.5, 16px);
            color: $gray-a;
            font-weight: bold;
            width: 120px;
            float: left;
            clear: both; }

          dd {
            @include setFont(12px, 1.5, 16px);
            color: $gray-a;
            float: left; } } } } } }
